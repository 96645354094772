form {
  width: 100%;
}

form input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px var(--color-white) inset!important;
  color: inherit!important;
}

.o-field {
  position: relative;
  margin-bottom: var(--gutter);
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 100%;
}

.o-field .o-field__help {
  display: block;
  color: var(--field-help-color);
}

.o-field .o-field__msg {
  display: none;
}

.o-field--error .o-field__msg,.o-field--error label {
  color: var(--field-error-color)!important;
}

.o-field--error .o-field__msg {
  display: block;
}

.o-field--error .o-input input,.o-field--error .o-select select {
  border-color: var(--field-error-color)!important;
}

.o-field--error i {
  display: none;
}

.o-field--error small {
  display: block;
}

.o-field--inline,.o-field__item--double {
  width: 48%;
}

@media only screen and (max-width:480px) {
  .o-field--inline {
    width: 100%;
  }
}

.o-input {
  width: 100%;
}

.o-input input[type=date],.o-input input[type=email],.o-input input[type=number],.o-input input[type=password],.o-input input[type=search],.o-input input[type=select],.o-input input[type=tel],.o-input input[type=text],.o-input textarea {
  display: block;
  background: none;
  width: 100%;
  padding: var(--gutter-small);
  outline: none;
  border: none;
  border: solid 1px var(--input-border-color);
  border-radius: var(--radius-small);
  -webkit-transition: .3s;
  transition: .3s;
  font-size: 1rem;
  line-height: 1.4rem;
  color: var(--input-color);
}

.o-input input[type=date]:hover,.o-input input[type=email]:hover,.o-input input[type=number]:hover,.o-input input[type=password]:hover,.o-input input[type=search]:hover,.o-input input[type=select]:hover,.o-input input[type=tel]:hover,.o-input input[type=text]:hover,.o-input textarea:hover {
  border-color: var(--input-border-hover-color);
}

.o-input input[type=date]:focus,.o-input input[type=email]:focus,.o-input input[type=number]:focus,.o-input input[type=password]:focus,.o-input input[type=search]:focus,.o-input input[type=select]:focus,.o-input input[type=tel]:focus,.o-input input[type=text]:focus,.o-input textarea:focus {
  border-color: var(--input-border-focus-color);
  -webkit-box-shadow: 1px 1px var(--gutter) rgba(0,0,0,.3);
  box-shadow: 1px 1px var(--gutter) rgba(0,0,0,.3);
}

.o-input input[type=date]:disabled,.o-input input[type=email]:disabled,.o-input input[type=number]:disabled,.o-input input[type=password]:disabled,.o-input input[type=search]:disabled,.o-input input[type=select]:disabled,.o-input input[type=tel]:disabled,.o-input input[type=text]:disabled,.o-input textarea:disabled {
  border-color: var(--input-border-disabled-color);
  color: var(--input-disabled-color);
}

.o-input input[type=date]:disabled::-webkit-input-placeholder,.o-input input[type=email]:disabled::-webkit-input-placeholder,.o-input input[type=number]:disabled::-webkit-input-placeholder,.o-input input[type=password]:disabled::-webkit-input-placeholder,.o-input input[type=search]:disabled::-webkit-input-placeholder,.o-input input[type=select]:disabled::-webkit-input-placeholder,.o-input input[type=tel]:disabled::-webkit-input-placeholder,.o-input input[type=text]:disabled::-webkit-input-placeholder,.o-input textarea:disabled::-webkit-input-placeholder {
  color: var(--input-placeholder-disabled-color);
}

.o-input input[type=date]:disabled::-moz-placeholder,.o-input input[type=email]:disabled::-moz-placeholder,.o-input input[type=number]:disabled::-moz-placeholder,.o-input input[type=password]:disabled::-moz-placeholder,.o-input input[type=search]:disabled::-moz-placeholder,.o-input input[type=select]:disabled::-moz-placeholder,.o-input input[type=tel]:disabled::-moz-placeholder,.o-input input[type=text]:disabled::-moz-placeholder,.o-input textarea:disabled::-moz-placeholder {
  color: var(--input-placeholder-disabled-color);
}

.o-input input[type=date]:disabled:-ms-input-placeholder,.o-input input[type=email]:disabled:-ms-input-placeholder,.o-input input[type=number]:disabled:-ms-input-placeholder,.o-input input[type=password]:disabled:-ms-input-placeholder,.o-input input[type=search]:disabled:-ms-input-placeholder,.o-input input[type=select]:disabled:-ms-input-placeholder,.o-input input[type=tel]:disabled:-ms-input-placeholder,.o-input input[type=text]:disabled:-ms-input-placeholder,.o-input textarea:disabled:-ms-input-placeholder {
  color: var(--input-placeholder-disabled-color);
}

.o-input input[type=date]:disabled:-moz-placeholder,.o-input input[type=email]:disabled:-moz-placeholder,.o-input input[type=number]:disabled:-moz-placeholder,.o-input input[type=password]:disabled:-moz-placeholder,.o-input input[type=search]:disabled:-moz-placeholder,.o-input input[type=select]:disabled:-moz-placeholder,.o-input input[type=tel]:disabled:-moz-placeholder,.o-input input[type=text]:disabled:-moz-placeholder,.o-input textarea:disabled:-moz-placeholder {
  color: var(--input-color-disabled);
}

.o-input input[type=date]:disabled:hover,.o-input input[type=email]:disabled:hover,.o-input input[type=number]:disabled:hover,.o-input input[type=password]:disabled:hover,.o-input input[type=search]:disabled:hover,.o-input input[type=select]:disabled:hover,.o-input input[type=tel]:disabled:hover,.o-input input[type=text]:disabled:hover,.o-input textarea:disabled:hover {
  cursor: not-allowed;
}

.o-input input[type=date]::-webkit-input-placeholder,.o-input input[type=email]::-webkit-input-placeholder,.o-input input[type=number]::-webkit-input-placeholder,.o-input input[type=password]::-webkit-input-placeholder,.o-input input[type=search]::-webkit-input-placeholder,.o-input input[type=select]::-webkit-input-placeholder,.o-input input[type=tel]::-webkit-input-placeholder,.o-input input[type=text]::-webkit-input-placeholder,.o-input textarea::-webkit-input-placeholder {
  color: var(--input-placeholder-color);
}

.o-input input[type=date]::-moz-placeholder,.o-input input[type=email]::-moz-placeholder,.o-input input[type=number]::-moz-placeholder,.o-input input[type=password]::-moz-placeholder,.o-input input[type=search]::-moz-placeholder,.o-input input[type=select]::-moz-placeholder,.o-input input[type=tel]::-moz-placeholder,.o-input input[type=text]::-moz-placeholder,.o-input textarea::-moz-placeholder {
  color: var(--input-placeholder-color);
}

.o-input input[type=date]:-ms-input-placeholder,.o-input input[type=email]:-ms-input-placeholder,.o-input input[type=number]:-ms-input-placeholder,.o-input input[type=password]:-ms-input-placeholder,.o-input input[type=search]:-ms-input-placeholder,.o-input input[type=select]:-ms-input-placeholder,.o-input input[type=tel]:-ms-input-placeholder,.o-input input[type=text]:-ms-input-placeholder,.o-input textarea:-ms-input-placeholder {
  color: var(--input-placeholder-color);
}

.o-input input[type=date]:-moz-placeholder,.o-input input[type=email]:-moz-placeholder,.o-input input[type=number]:-moz-placeholder,.o-input input[type=password]:-moz-placeholder,.o-input input[type=search]:-moz-placeholder,.o-input input[type=select]:-moz-placeholder,.o-input input[type=tel]:-moz-placeholder,.o-input input[type=text]:-moz-placeholder,.o-input textarea:-moz-placeholder {
  color: var(--input-placeholder-color);
}

.o-input input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #fff inset!important;
  box-shadow: inset 0 0 0 1000px #fff!important;
  color: inherit!important;
}

.o-input--prefix {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.o-input--prefix span {
  color: var(--input-prefix-color);
  margin-right: var(--gutter-small);
}

.o-input--postfix {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.o-input--postfix span {
  color: var(--input-postfix-color);
  margin-left: var(--gutter-small);
}

.o-radio label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
  -webkit-transition: .3s;
  transition: .3s;
  color: var(--color-darker);
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: var(--weight-regular);
}

.o-radio input[type=radio] {
  margin-right: var(--gutter-small);
}

.o-radio input[type=radio]:hover {
  border-color: var(--color-dark);
}

.o-radio input[type=radio]:focus {
  border-color: var(--color-darker);
  -webkit-box-shadow: 1px 1px var(--gutter) rgba(0,0,0,.3);
  box-shadow: 1px 1px var(--gutter) rgba(0,0,0,.3);
}

.o-radio input[type=radio]:disabled {
  border-color: var(--color-light);
  pointer-events: none;
}

.o-radio .o-field__help {
  display: block;
  color: var(--color-darker);
}

.o-radio input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #fff inset!important;
  box-shadow: inset 0 0 0 1000px #fff!important;
  color: inherit!important;
}

.o-radio--error label,.o-radio--error small {
  color: var(--color-red)!important;
}

.o-radio--error i {
  display: none;
}

.o-radio--error input[type=email],.o-radio--error input[type=password],.o-radio--error input[type=search],.o-radio--error input[type=select],.o-radio--error input[type=tel],.o-radio--error input[type=text],.o-radio--error textarea {
  border-color: var(--color-red)!important;
  color: var(--color-red)!important;
  background-size: 10px;
  font-weight: var(--weight-light);
}

.o-radio--error small {
  display: block;
}

.o-radio--inline ul {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.o-radio--inline ul li {
  width: 48%;
  margin-bottom: var(--gutter-small);
}

.o-radio--double {
  width: 48%;
}

.o-radio__prefix {
  position: absolute;
  bottom: 0;
  left: 0;
  color: var(--color-light);
  border-bottom: solid 2px var(--color-white);
  opacity: 0;
}

.o-checkbox label {
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  -webkit-transition: .3s;
  transition: .3s;
  color: var(--color-darker);
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: var(--weight-regular);
}

.o-checkbox input[type=date],.o-checkbox input[type=email],.o-checkbox input[type=number],.o-checkbox input[type=password],.o-checkbox input[type=search],.o-checkbox input[type=select],.o-checkbox input[type=tel],.o-checkbox input[type=text],.o-checkbox textarea {
  display: block;
  background: none;
  width: 100%;
  padding: var(--gutter-small);
  outline: none;
  border: none;
  border: solid 1px var(--color);
  border-radius: var(--radius-small);
  -webkit-transition: .3s;
  transition: .3s;
  font-size: 1rem;
  line-height: 1.4rem;
  color: var(--color-darker);
}

.o-checkbox select {
  display: block;
  background: none;
  width: 100%;
  padding: var(--gutter-small);
  outline: none;
  border: none;
  border: solid 1px var(--color);
  border-radius: var(--radius-small);
  -webkit-transition: .3s;
  transition: .3s;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1.4rem;
  color: var(--color-darker);
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-transition: .5s;
  transition: .5s;
}

.o-checkbox select:hover {
  border-color: var(--color-dark);
}

.o-checkbox select:focus {
  border-color: var(--color-darker);
  -webkit-box-shadow: 1px 1px var(--gutter) rgba(0,0,0,.3);
  box-shadow: 1px 1px var(--gutter) rgba(0,0,0,.3);
}

.o-checkbox select:disabled {
  border-color: var(--color-light);
  pointer-events: none;
}

.o-checkbox input[type=checkbox],.o-checkbox input[type=radio] {
  margin-right: var(--gutter-small);
}

.o-checkbox ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0;
  padding: 0;
  max-width: 100%;
  margin-bottom: 0!important;
}

.o-checkbox ul li {
  display: block;
  list-style: none;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start!important;
  -ms-flex-pack: start!important;
  justify-content: flex-start!important;
}

.o-checkbox ul li label {
  margin: 0;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: var(--weight-light);
}

.o-checkbox ul li .o-field__help {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 100%;
  display: block;
  margin-left: 25px;
}

.o-checkbox .o-field__help {
  display: block;
  color: var(--color-darker);
}

.o-checkbox input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #fff inset!important;
  box-shadow: inset 0 0 0 1000px #fff!important;
  color: inherit!important;
}

.o-checkbox--error label,.o-checkbox--error small {
  color: var(--color-red)!important;
}

.o-checkbox--error i {
  display: none;
}

.o-checkbox--error input[type=email],.o-checkbox--error input[type=password],.o-checkbox--error input[type=search],.o-checkbox--error input[type=select],.o-checkbox--error input[type=tel],.o-checkbox--error input[type=text],.o-checkbox--error textarea {
  border-color: var(--color-red)!important;
  color: var(--color-red)!important;
  background-size: 10px;
  font-weight: var(--weight-light);
}

.o-checkbox--error small {
  display: block;
}

.o-checkbox--inline ul {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.o-checkbox--inline ul li {
  width: 48%;
  margin-bottom: var(--gutter-small);
}

.o-checkbox--double {
  width: 48%;
}

.o-checkbox__prefix {
  position: absolute;
  bottom: 0;
  left: 0;
  color: var(--color-light);
  border-bottom: solid 2px var(--color-white);
  opacity: 0;
}

.o-select {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 100%;
}

.o-select select {
  display: block;
  background: none;
  width: 100%;
  padding: var(--gutter-small) var(--gutter-medium) var(--gutter-small) var(--gutter-small);
  outline: none;
  border: none;
  border: solid 1px var(--input-border-color);
  border-radius: var(--radius-small);
  -webkit-transition: .3s;
  transition: .3s;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1.4rem;
  color: var(--input-color);
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-transition: .5s;
  transition: .5s;
}

.o-select select:hover {
  border-color: var(--input-border-hover-color);
}

.o-select select:focus {
  border-color: var(--input-border-focus-color);
  -webkit-box-shadow: 1px 1px var(--gutter) rgba(0,0,0,.3);
  box-shadow: 1px 1px var(--gutter) rgba(0,0,0,.3);
}

.o-select select:disabled {
  border-color: var(--input-border-disabled-color);
  color: var(--input-disabled-color);
}

.o-select select:disabled:hover {
  cursor: not-allowed;
}

.o-select select:hover {
  cursor: pointer;
}

.o-select .o-icon {
  position: absolute;
  top: 50%;
  right: var(--gutter-small);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 15px;
  height: 15px;
}

.o-select .o-icon__path {
  fill: var(--color-dark);
}

.o-select select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #fff inset!important;
  box-shadow: inset 0 0 0 1000px #fff!important;
  color: inherit!important;
}

.o-select--error label,.o-select--error small {
  color: var(--field-error-color)!important;
}

.o-select--error i {
  display: none;
}

.o-select--error input[type=email],.o-select--error input[type=password],.o-select--error input[type=search],.o-select--error input[type=select],.o-select--error input[type=tel],.o-select--error input[type=text],.o-select--error textarea {
  border-color: var(--field-error-color)!important;
  color: var(--field-error-color)!important;
  background-size: 10px;
  font-weight: var(--weight-light);
}

.o-select--error small {
  display: block;
}

.o-select--inline ul {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.o-select--inline ul li {
  width: 48%;
  margin-bottom: var(--gutter-small);
}

.o-select--double {
  width: 48%;
}

.o-select__prefix {
  position: absolute;
  bottom: 0;
  left: 0;
  color: var(--color-light);
  border-bottom: solid 2px var(--color-white);
  opacity: 0;
}

.o-controls,.o-controls ul {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.o-controls ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0;
  padding: 0;
  max-width: 100%;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-bottom: var(--gutter-small);
}

.o-controls ul li {
  display: block;
  list-style: none;
  padding: 0;
}

.o-controls ul li:not(:last-child) {
  margin-right: auto;
}

.o-controls small {
  display: block;
  font-size: .75rem;
  line-height: .9rem;
  text-align: right;
}

.o-controls small:not(:last-child) {
  margin-bottom: var(--gutter-small);
}

@media only screen and (max-width:480px) {
  .o-controls ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .o-controls ul li {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    width: 100%;
  }

  .o-controls ul li:not(:last-child) {
    margin-right: 0;
    margin-bottom: var(--gutter-small);
  }

  .o-controls ul li .o-btn {
    display: block;
    width: 100%;
  }
}


.f-partner {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.f-partner,.f-partner__logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.f-partner__logo {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 200px;
  margin: 0 var(--gutter) 0 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.f-partner__details {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-left: solid 1px var(--color-light);
  padding-left: var(--gutter);
}

@media only screen and (max-width:480px) {
  .f-partner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .f-partner__logo {
    width: 100%;
    margin: 0;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .f-partner__details {
    border-left: none;
    padding-left: 0;
    padding-top: var(--gutter-small);
  }
}


.f-empty {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: var(--gutter-medium);
}

.f-empty svg {
  margin-bottom: var(--gutter);
}

.f-empty p {
  font-weight: var(--weight-light);
  font-size: 1.333rem;
  line-height: 1.7rem;
}

.f-empty--left {
  text-align: left;
}

.f-loader {
  padding: var(--gutter-large);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.f-loader small {
  display: block;
  color: var(--color-dark);
  text-transform: uppercase;
}

.f-loader svg {
  height: 100px;
}

.f-loader--mini {
  padding: 0;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.f-loader--mini svg {
  width: 40px;
  height: 40px;
  margin-right: var(--gutter-small);
}

.f-loader--fullscreen {
  height: 100vh;
  width: 100vw;
}

.f-profile-graph .circle__outer {
  fill: #f1f1f1;
}

.f-profile-graph .circle__middle {
  fill: #d3dae2;
}

.f-profile-graph .circle__inner {
  fill: #fff;
}

.f-profile-graph .circle__dot {
  fill: #3c438b;
}

.f-profile-graph .text {
  fill: #657d95;
}

.f-profile-graph .isActive .circle__outer {
  fill: #feead3;
}

.f-profile-graph .isActive .circle__middle {
  fill: #feba75;
}

.f-profile-graph .isActive .circle__inner {
  fill: #fff;
}

.f-profile-graph .isActive .circle__dot {
  fill: #3c438b;
}

.f-profile-graph .isActive .text {
  fill: #ff911a;
}

.f-profile-graph .st0 {
  fill: #b4dde1;
}

.f-profile-graph .st1 {
  opacity: .12;
  fill: #657d95;
  enable-background: new;
}

.f-profile-graph .st2 {
  fill: #3c438b;
}

.f-profile-graph .st3 {
  fill: #a6becc;
}

.f-profile-graph .st4 {
  fill: #657d95;
}

.f-profile-graph .st5 {
  fill: #feead3;
}

.f-profile-graph .st6 {
  fill: #feba75;
}

.f-profile-graph .st7 {
  fill: #fff;
}

.f-profile-graph .st8 {
  fill: #ff911a;
}

.f-profile-graph .st10 {
  fill: #d3dae2;
}


.f-task {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  background: var(--color-white);
  width: 45%;
  -webkit-box-shadow: 1px 1px 20px var(--color-black);
  box-shadow: 1px 1px 20px var(--color-black);
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition: .5s;
  transition: .5s;
  overflow: auto;
  padding: var(--gutter-medium);
}

.f-task .o-notifications {
  margin: -var(--gutter-medium) -var(--gutter-medium) var(--gutter-medium);
}

.f-task--hidden {
  -webkit-transform: translate3d(150%,0,0);
  transform: translate3d(150%,0,0);
}

.f-task--static {
  position: relative;
}

.f-task__header {
  margin-bottom: var(--gutter-medium);
}

.f-task__header ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0;
  padding: 0;
  max-width: 100%;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  font-size: .75rem;
  line-height: .9rem;
  margin-bottom: var(--gutter-small);
  margin-top: -var(--gutter);
}

.f-task__header ul li {
  display: block;
  list-style: none;
  padding: 0;
}

.f-task__header ul li:not(:last-child) {
  margin-right: var(--gutter-small);
}

.f-task__header h2,.f-task__header h3 {
  margin: 0;
  font-family: var(--family-secondary);
  font-weight: var(--weight-bold);
}

.f-task__header p {
  margin-top: var(--gutter-small);
}

.f-task__img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  margin-bottom: var(--gutter-medium);
}

.f-task__img svg {
  margin-bottom: var(--gutter);
}

.f-task__img h3 {
  font-family: var(--family-secondary);
  font-weight: var(--weight-bold);
}

.f-task__img--small svg {
  width: 50%;
  height: auto;
}

@media only screen and (max-width:480px) {
  .f-task {
    width: 100%;
    top: 0;
    z-index: 999999;
    padding: var(--gutter-medium) var(--gutter) var(--gutter);
  }
}

.f-mobile-nav {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.f-mobile-nav__menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 80%;
  -webkit-transform: translateX(120%);
  transform: translateX(120%);
  -webkit-transition: .3s;
  transition: .3s;
  background: var(--color-secondary);
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: var(--gutter);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.f-mobile-nav__menu--open {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-box-shadow: 1px 1px 1000px var(--color-black);
  box-shadow: 1px 1px 1000px var(--color-black);
  -webkit-transition: .3s;
  transition: .3s;
}

.f-mobile-nav__menu .o-btn-icon {
  position: absolute;
  top: var(--gutter-small);
  right: var(--gutter-small);
}

.f-mobile-nav__menu a {
  color: var(--color-accent);
}

.f-mobile-nav__primary {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0;
  padding: 0;
  max-width: 100%;
  margin-bottom: var(--gutter);
}

.f-mobile-nav__primary li {
  display: block;
  list-style: none;
  padding: 0;
  font-size: 1.333rem;
  line-height: 1.7rem;
}

.f-mobile-nav__primary li a {
  font-weight: var(--weight-medium);
  display: block!important;
}

.f-mobile-nav__primary li:not(:last-child) {
  border-bottom: solid 1px var(--color-darker);
  padding-bottom: var(--gutter-small);
  margin-bottom: var(--gutter-small);
}

.f-mobile-nav__secondary {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0;
  padding: 0;
  max-width: 100%;
  border: solid 1px var(--color-darker);
  border-radius: var(--radius-small);
  padding: var(--gutter-small);
}

.f-mobile-nav__secondary li {
  display: block;
  list-style: none;
  padding: 0;
}

.f-docupload {
  border: solid 1px var(--color);
  border-radius: var(--radius-small);
  background: var(--color-white);
  position: relative;
}

.f-docupload:after {
  display: none;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--color);
  opacity: .4;
}

.f-docupload--disabled:after {
  display: block;
}

.f-docupload__dropzone {
  height: 120px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: var(--gutter);
  text-align: center;
}

.f-docupload__dropzone:hover {
  cursor: pointer;
}

.f-docupload__dropzone p {
  margin-bottom: var(--gutter-small);
  font-weight: var(--weight-regular);
}

.f-docupload__dropzone small {
  text-transform: uppercase;
  color: var(--color-dark);
  font-weight: var(--weight-light);
}

.f-docupload__uploading {
  padding: var(--gutter);
  height: 120px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.f-docupload__uploading .f-loader {
  padding: 0;
}

.f-docupload__uploading .f-loader svg {
  height: 60px;
  width: 60px;
}

.f-docupload__list {
  border-top: solid 1px var(--color);
  padding: var(--gutter-small);
}

.f-docupload__list h4 {
  margin-bottom: var(--gutter-small);
  color: var(--color-dark);
}

.f-docupload__list ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0;
  padding: 0;
  max-width: 100%;
}

.f-docupload__list ul li {
  display: block;
  list-style: none;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: solid 1px var(--color-light);
  padding: var(--gutter-small) 0;
}

.f-docupload__list ul li figure {
  margin: 0;
  margin-right: var(--gutter-small);
}

.f-docupload__list ul li figure .o-icon {
  width: 15px;
  height: 15px;
}

.f-docupload__list ul li figure .o-icon__path {
  fill: var(--color);
}

.f-docupload__list ul li .o-icon {
  width: 15px;
  height: 15px;
}

.f-docupload__list ul li .o-btn,.f-docupload__list ul li .o-icon {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
}

.f-docupload__list ul li span {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: block;
  font-size: .75rem;
  line-height: .9rem;
}

.f-reference {
  background: var(--color-secondary);
  border-radius: var(--radius-small);
  padding: var(--gutter);
  margin-bottom: var(--gutter);
}

.f-reference span {
  color: var(--color-accent)!important;
}

.f-fees__icon {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  background: transparent;
  width: 30px;
  height: 30px;
  -webkit-transition: .3s;
  transition: .3s;
  border: solid 2px var(--color);
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.f-fees__icon:hover {
  cursor: pointer;
}

.f-fees__icon:hover .o-icon {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.f-fees__icon .o-icon {
  width: 15px;
  height: 15px;
  -webkit-transition: .3s;
  transition: .3s;
}

.f-fees__icon .o-icon__path {
  fill: var(--color-dark);
}

.f-fees__item {
  display: -webkit-box!important;
  display: -ms-flexbox!important;
  display: flex!important;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%!important;
}

.f-fees__item .f-fees__minortitle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.f-fees__item .f-fees__minortitle .o-tooltip {
  margin-left: 5px;
}

.f-fees__item--disabled {
  opacity: .4;
}


.u-padding {
  padding: var(--gutter);
}

.u-noMargin {
  margin: 0;
}

.u-list-icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0;
  padding: 0;
  max-width: 100%;
}

.u-list-icons li {
  display: block;
  list-style: none;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: var(--gutter-small);
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.u-list-icons span {
  color: var(--color-dark);
}

.u-list-icons a,.u-list-icons span {
  margin-top: var(--gutter-small);
}


.u-list-vertical label {
  font-weight: var(--weight-light)!important;
}

.o-fieldset--temp {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.u-downloadLink {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.u-downloadLink h4 {
  margin: 0;
  color: var(--color-blue)!important;
}

.u-downloadLink .o-icon {
  width: 20px;
  height: 20px;
}

.u-downloadLink .o-icon__path {
  fill: var(--color);
}

.u-border--bottom {
  border-bottom: solid 2px var(--color-white);
}

.u-paint--white {
  background: var(--color-white);
}


.u-invisible {
  opacity: 0;
}

.u-data__label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.u-data__label .o-tooltip {
  margin-left: 5px;
}

.u-tag {
  background: var(--color-blue);
  color: var(--color-white);
  padding: 2px 5px;
  border-radius: var(--radius-small);
  font-weight: var(--weight-regular);
  font-size: .75rem;
  line-height: .9rem;
}

.u-tag--block {
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.u-tag--platform {
  background: var(--color-blue-light);
  color: var(--color-blue-dark);
}

.u-tag--independent {
  background: var(--color-orange-light);
  color: var(--color-orange-dark);
}

@-webkit-keyframes FLICKER {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes FLICKER {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.a-flicker {
  -webkit-animation-name: FLICKER;
  animation-name: FLICKER;
  -webkit-animation-duration: .8s;
  animation-duration: .8s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.a-flicker-2 {
  -webkit-animation-name: FLICKER;
  animation-name: FLICKER;
  -webkit-animation-duration: .8s;
  animation-duration: .8s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: .25s;
  animation-delay: .25s;
}

.a-flicker-3 {
  -webkit-animation-name: FLICKER;
  animation-name: FLICKER;
  -webkit-animation-duration: .8s;
  animation-duration: .8s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: .5s;
  animation-delay: .5s;
}

.a-fadeIn {
  opacity: 0;
  -webkit-animation-name: FLICKER;
  animation-name: FLICKER;
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.a-pushedDown {
  -webkit-transform: translate3d(0,250px,0) scale(.85);
  transform: translate3d(0,250px,0) scale(.85);
  opacity: .45;
  -webkit-transition: .6s .5s;
  transition: .6s .5s;
}

.a-pushedDown:hover {
  cursor: pointer;
  opacity: .55;
}

.a-pushedLeft {
  -webkit-transform: translate3d(-10%,0,0) scale(.95);
  transform: translate3d(-10%,0,0) scale(.95);
  opacity: .45;
  -webkit-transition: 1s .5s;
  transition: 1s .5s;
}

.a-pushedLeft:hover {
  cursor: pointer;
  opacity: .55;
}

.a-dashbar-enter {
  -webkit-transform: translate3d(0,-100%,0);
  transform: translate3d(0,-100%,0);
}

.a-dashbar-enter-active {
  -webkit-transition: -webkit-transform .5s .5s;
  transition: -webkit-transform .5s .5s;
  transition: transform .5s .5s;
  transition: transform .5s .5s,-webkit-transform .5s .5s;
}

.a-dashbar-enter-active,.a-dashbar-exit {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.a-dashbar-exit-active {
  -webkit-transition: -webkit-transform .5s;
  transition: -webkit-transform .5s;
  transition: transform .5s;
  transition: transform .5s,-webkit-transform .5s;
}

.a-dashbar-exit-active,.a-dashboard-enter {
  -webkit-transform: translate3d(0,-100%,0);
  transform: translate3d(0,-100%,0);
}

.a-dashboard-enter-active {
  -webkit-transition: -webkit-transform .5s .5s;
  transition: -webkit-transform .5s .5s;
  transition: transform .5s .5s;
  transition: transform .5s .5s,-webkit-transform .5s .5s;
}

.a-dashboard-enter-active,.a-dashboard-exit {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.a-dashboard-exit-active {
  -webkit-transform: translate3d(0,-100%,0);
  transform: translate3d(0,-100%,0);
  -webkit-transition: -webkit-transform .5s;
  transition: -webkit-transform .5s;
  transition: transform .5s;
  transition: transform .5s,-webkit-transform .5s;
}

.a-task-enter {
  -webkit-transform: translate3d(100%,0,0);
  transform: translate3d(100%,0,0);
}

.a-task-enter-active {
  -webkit-transition: -webkit-transform .5s .5s;
  transition: -webkit-transform .5s .5s;
  transition: transform .5s .5s;
  transition: transform .5s .5s,-webkit-transform .5s .5s;
}

.a-task-enter-active,.a-task-exit {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.a-task-exit-active {
  -webkit-transform: translate3d(100%,0,0);
  transform: translate3d(100%,0,0);
  -webkit-transition: -webkit-transform .5s;
  transition: -webkit-transform .5s;
  transition: transform .5s;
  transition: transform .5s,-webkit-transform .5s;
}

.a-fade-enter {
  position: absolute;
  width: 100%;
  opacity: 0;
}

.a-fade-enter-active {
  opacity: 1;
  -webkit-transition: -webkit-transform 1s;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s,-webkit-transform 1s;
}

.a-fade-enter-done {
  opacity: 1;
}

.a-fade-exit {
  position: absolute;
  width: 100%;
  opacity: 1;
}

.a-fade-exit-active {
  opacity: 0;
  -webkit-transition: -webkit-transform 1s;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s,-webkit-transform 1s;
}

@-webkit-keyframes ROTATE {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes ROTATE {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

.a-rotate {
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-animation-name: ROTATE;
  animation-name: ROTATE;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

@-webkit-keyframes PULSE {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  to {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
}

@keyframes PULSE {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  to {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
}

.a-pulsating-dot .circle {
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-animation-name: PULSE;
  animation-name: PULSE;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

ul {
  padding-left: 0;
}

ul li::marker {
  display: none!important;
  opacity: 0!important;
}

ul li:before {
  display: none;
}

.o-btn--primary {
  background-color: var(--btn-primary-bg)!important;
  border-color: var(--btn-primary-border)!important;
  color: var(--btn-primary-color)!important;
}

.o-btn--primary:hover {
  background-color: var(--btn-primary-bg-hover)!important;
  border-color: var(--btn-primary-border-hover)!important;
  color: var(--btn-primary-color-hover)!important;
}

.o-btn--primary .o-btn--loading .o-btn__loader {
  opacity: 1;
}

.o-btn--primary .o-btn--loading span {
  color: var(--btn-primary-bg-hover)!important;
}

.o-btn--secondary {
  background-color: var(--btn-secondary-bg)!important;
  border-color: var(--btn-secondary-border)!important;
  color: var(--btn-secondary-color)!important;
}

.o-btn--secondary:hover {
  background-color: var(--btn-secondary-bg-hover)!important;
  border-color: var(--btn-secondary-border-hover)!important;
  color: var(--btn-secondary-color-hover)!important;
}

.o-btn--secondary .o-btn--loading .o-btn__loader {
  opacity: 1;
}

.o-btn--secondary .o-btn--loading span {
  color: var(--btn-secondary-bg-hover)!important;
}

.o-btn--tertiaryCancel {
  background-color: var(--btn-tertiaryCancel-bg)!important;
  border-color: var(--btn-tertiaryCancel-border)!important;
  color: var(--btn-tertiaryCancel-color)!important;
}

.o-btn--tertiaryCancel:hover {
  background-color: var(--btn-tertiaryCancel-bg-hover)!important;
  border-color: var(--btn-tertiaryCancel-border-hover)!important;
  color: var(--btn-tertiaryCancel-color-hover)!important;
}

.o-btn--tertiaryCancel .o-btn--loading .o-btn__loader {
  opacity: 1;
}

.o-btn--tertiaryCancel .o-btn--loading span {
  color: var(--btn-tertiaryCancel-bg-hover)!important;
}

.o-btn-icon--default {
  border-color: var(--btn-i-default-border);
  background-color: var(--btn-i-default-bg);
}

.o-btn-icon--default .o-icon__path {
  fill: var(--btn-i-default-icon);
}

.o-btn-icon--default:hover {
  background-color: var(--btn-i-default-bg-hover);
  border-color: var(--btn-i-default-border-hover);
}

.o-btn-icon--default:hover .o-icon__path {
  fill: var(--btn-i-default-icon-hover);
}

.o-btn-icon--dashbar {
  border-color: var(--btn-i-dashbar-border);
  background-color: var(--btn-i-dashbar-bg);
}

.o-btn-icon--dashbar .o-icon__path {
  fill: var(--btn-i-dashbar-icon);
}

.o-btn-icon--dashbar:hover {
  background-color: var(--btn-i-dashbar-bg-hover);
  border-color: var(--btn-i-dashbar-border-hover);
}

.o-btn-icon--dashbar:hover .o-icon__path {
  fill: var(--btn-i-dashbar-icon-hover);
}

.o-btn-icon--sidebar {
  border-color: var(--btn-i-sidebar-border);
  background-color: var(--btn-i-sidebar-bg);
}

.o-btn-icon--sidebar .o-icon__path {
  fill: var(--btn-i-sidebar-icon);
}

.o-btn-icon--sidebar:hover {
  background-color: var(--btn-i-sidebar-bg-hover);
  border-color: var(--btn-i-sidebar-border-hover);
}

.o-btn-icon--sidebar:hover .o-icon__path {
  fill: var(--btn-i-sidebar-icon-hover);
}

.o-btn-icon--settings {
  border-color: var(--btn-i-settings-border);
  background-color: var(--btn-i-settings-bg);
}

.o-btn-icon--settings .o-icon__path {
  fill: var(--btn-i-settings-icon);
}

.o-btn-icon--settings:hover {
  background-color: var(--btn-i-settings-bg-hover);
  border-color: var(--btn-i-settings-border-hover);
}

.o-btn-icon--settings:hover .o-icon__path {
  fill: var(--btn-i-settings-icon-hover);
}

.o-btn-icon--minor {
  border-color: var(--btn-i-minor-border);
  background-color: var(--btn-i-minor-bg);
}

.o-btn-icon--minor .o-icon__path {
  fill: var(--btn-i-minor-icon);
}

.o-btn-icon--minor:hover {
  background-color: var(--btn-i-minor-bg-hover);
  border-color: var(--btn-i-minor-border-hover);
}

.o-btn-icon--minor:hover .o-icon__path {
  fill: var(--btn-i-minor-icon-hover);
}

.o-data-table__row .o-data-table__col {
  vertical-align: middle;
}

.f-article__body img {
  max-width: 100%;
}

.o-summary h2 {
  margin-bottom: var(--gutter);
}

.o-subnav h5 {
  color: var(--color-accent);
  background: var(--color-secondary);
}

.o-subnav li a {
  font-weight: var(--weight-medium)!important;
}

.o-subnav li a:hover {
  color: var(--color-secondary);
}


.o-media svg {
  margin-right: 5px;
}

.o-toolbar h3 {
  font-weight: var(--weight-light)!important;
}


.o-fieldset h2,.o-fieldset h3,.o-fieldset h4,.o-fieldset h5,.o-fieldset h6,.o-fieldset ol,.o-fieldset p,.o-fieldset ul {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 100%;
}

.o-fieldset .o-information {
  margin-bottom: var(--gutter);
}

.o-steps__item {
  border-width: 4px;
}

.o-steps__item--current,.o-steps__item--current .o-btn-icon {
  border-color: var(--color-primary)!important;
}

.o-steps__item--current .o-btn-icon {
  background: var(--color-primary);
}

.o-steps__item--complete {
  border-color: rgba(var(--color-accent),0)!important;
}

.o-steps__item--complete .o-btn-icon {
  background: var(--color-accent);
  border-color: var(--color-accent)!important;
}

.o-steps__item--complete .o-btn-icon:hover {
  background: var(--color-primary);
}

.o-steps__item--complete .o-btn-icon:hover,.o-steps__item--complete.o-steps__item--current {
  border-color: var(--color-primary)!important;
}

.o-steps__item--complete.o-steps__item--current .o-btn-icon {
  background: var(--color-primary)!important;
  border-color: var(--color-primary)!important;
}

.o-information p {
  margin-top: 0!important;
}

.o-btn--tertiaryCancel {
  padding: 0;
  border-radius: 0;
  font-size: .75rem;
  line-height: .9rem;
}

.o-btn--tertiaryCancel:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.o-btn--disabled {
  opacity: .84;
  pointer-events: none;
}

.o-btn--disabled:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.o-btn--loading {
  background: var(--color-lighter)!important;
  border-color: var(--color-lighter)!important;
  padding-top: 0;
  padding-bottom: 0;
}

.o-btn--loading svg {
  width: 45px!important;
}

.o-btn--loading:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}


.o-controls .f-pointer {
  margin-left: auto;
  width: 50%;
}

.o-controls .f-pointer .o-icon {
  margin: 0 auto var(--gutter-small);
}

.o-controls .f-pointer p {
  text-align: center;
}

.c-transactionStatement--row-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;

}

.c-transactionStatement--col-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-end;
}

.c-transactionStatement .info-text p {
 margin-bottom: 15px;
 font-size: 8pt;
}

.buttonLoader {
  height: 36px;
  padding-top: 10px;
}
.c-transactionStatement .error-text p {
 margin-right: 45px;
 margin-bottom: 15px;
 font-size: 8pt;
 font-weight: bold;
 color: var(--color-red);
}
.c-transactionStatement .loading-text p {
 margin-right: 45px;
 margin-bottom: 15px;
 font-size: 8pt;
 font-weight: bold;
 color: var(--color-black);
}

.datepicker {
  font-size: 10pt;
  cursor: pointer;
}

.datepicker__wrapper {
  position: relative;
  z-index: 900 !important;
}

.datepicker-calendar__icon {
  width: 36px; 
  height: 36px; 
  position: absolute; 
  bottom: 5px; 
  right: 5px; 
  cursor: pointer;
}

.datepicker::placeholder {
  color: gray;
  font-weight: lighter;
  font-size: 9pt;
}

.datepicker::-moz-placeholder {
  color:gray;
  font-size: 9pt;
  font-weight: lighter;
}

.datepicker:-ms-input-placeholder {
  color: gray;
  font-size: 9pt;
  font-weight: lighter;
}
/*Fix for safari nav arrows until control gets official fix*/
.react-datepicker__navigation-icon {
  width: 0;
}

