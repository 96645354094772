/* CSS [ UTILITIES ] ######################################################## */

/* 1. JUSTIFIED LIST ++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

.u-justified {
  display: flex;
  justify-content: space-between;
}

/* 1. END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

/* 2. UNDERLINED ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

.u-underlined:not(:last-child) {
  border-bottom: solid 1px var(--color-light);
  margin-bottom: var(--gutter-small)!important;
  padding-bottom: var(--gutter-small)!important;
}

/* 2. END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

/* 3. FLAG ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

.u-flag {
  display: inline-block;
  width: 25px;
  border: solid 2px var(--color-white);
}

/* 3. END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

/* 4. CHECKBOX ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

.o-checkbox label {
  display: flex;
  align-items: center;
  font-size: var(--size-small);
  line-height: var(--lineheight-small);
}

.u-checkbox-error {
  border: 1px solid var(--color-red)!important;
}

/* 4. END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

/* 4. CHECKBOX ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

.u-download-link {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.u-download-link > div {
  flex: 1;
  width:100%;
}

.u-download-link > div h4 {
  color: var(--color-primary);
  margin:0;
}

.u-download-link:hover > div h4 {
  color: var(--color-secondary);
}

.u-download-link__image {
  flex: none;
  margin-left: var(--gutter-small);
  width: 40px;
  height: 40px;

  border-radius: 100%;
  background: var(--color-light);
  padding: 10px;

}
.u-download-link:hover .u-download-link__image {
  color: var(--color-secondary);
}

.u-download-link__image svg {

  width: 20px;
  height: 20px;

}

/* 4. END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

/* 5. LIST BOX ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

.u-list-box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--gutter);
  list-style: none;
  margin: 0;
}

.u-list-box small {
  display: block;
  margin-top: 5px;
  margin-left: 36px;
}
.u-list-box-line-height {
  line-height: 18px;
}

@media screen and (max-width: 480px) {
  .u-list-box {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--gutter);
    list-style: none;
    margin: 0;
  }
  
  .u-list-box small {
    display: block;
    margin-top: 5px;
    margin-left: 36px;
  }
}

/* 5. END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

/* 6. HIDE ELEMENTS */
.u-hidden {
  display: none;
}
/* 6. END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

/* 7. TEXT */
.u-text-darker {
  color: var(--color-darker);
}
.u-text-small {
  font-size: small;
}
.u-text-lighter {
  color: var(--color-dark);
}
.u-text-error {
  color: var(--color-red);
}

/* 7. END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

/* 8. IFRAME ASPECT RATIO +++++++++++++++++++++++++++++++++++++++++++++++++++ */

.u-iframe {
  padding-bottom: 56.25%;
  }
.u-iframe > iframe{
  position: absolute;
  top: 10;
  left: 10;
  width: 95%;
  height: 95%;
  }

/* 7. END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

/* 8. TAB +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

.u-tab {
  display: flex;
  background: var(--color-light);
  border-radius: 10px;
  padding: 5px var(--gutter-small)!important;
  padding-left: var(--gutter-small)!important;
  padding-right: var(--gutter-small)!important;
}

.u-tab--ismodified {
  background: rgb(var(--color-orange-light));
}

.u-tab--ismodified .p-sticker small {
  color: rgb(var(--color-orange-dark))!important;
}
.u-tab--ismodified .p-sticker .p-data strong {
  color: rgb(var(--color-orange-dark))!important;
}

.u-tab--ismodified .p-sticker .p-data i {
  color: rgb(var(--color-orange-dark))!important;
}

.u-tab a {
  display: block;
}

.u-tab a:not(:last-child) {
  margin-right: var(--gutter-small);
}

.u-tab-wallet {
  display: flex;
  padding: 5px var(--gutter-small)!important;
  padding-left: var(--gutter-small)!important;
  padding-right: var(--gutter-small)!important;
}
/* 7. END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

/* 7. END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

.u-badge {
  background-color: var(--color-dark);
  width: 15px;
  height: 15px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -5px;
  right: -5px;
  font-style: normal;
  font-weight: var(--weight-bold);
  color: var(--color-white);
  padding: 2px;
}
.u-badge > svg {
  width: 8px;
  height: 8px;
}

.u-badge--red {
  background-color: var(--color-red);

}
.u-badge--green {
  background-color: var(--color-green);

}

/* 7. END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

.u-margin-left--small {
  margin-left: var(--gutter-small);
}

.u-margin-bottom--small {
  margin-bottom: var(--gutter-small);
}
.u-margin-bottom {
  margin-bottom: 80px;
}

.p-flag {
  font-weight: var(--weight-regular)!important;
}


.u-underline {
  border-bottom: solid 1px var(--color-light);
}


/* END OF FILE ############################################################## */


