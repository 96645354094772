/* CSS [ VARIABLES ] ######################################################## */

:root {

  --color-wm-blueDarker-rgb: 10,16,30;

  --color-wm-blue: #3d4488;
  --color-wm-blueDark: #18223b;
  --color-wm-blueDarker: rgb(var(--color-wm-blueDarker-rgb));
  
  --color-wm-blueElectric: #03ddf6;

  --color-primary:        var(--color-wm-blue);
  --color-secondary:      var(--color-wm-blueDark);
  --color-accent:         var(--color-wm-blueElectric);
  
  /* 2. BUTTONS +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

  /* 2.1. PRIMARY BUTTON ---------------------------------------------------- */

  --btn-primary--color:   var(--color-accent);
  --btn-primary--colorH:  var(--color-secondary);
  --btn-primary--bg:      var(--color-secondary);
  --btn-primary--bgH:     var(--color-accent);
  --btn-primary--border:  var(--color-secondary);
  --btn-primary--borderH: var(--color-accent);

  /* 2.1. END --------------------------------------------------------------- */

  /* 2.2. SECONDARY BUTTON -------------------------------------------------- */

  --btn-secondary--color:   var(--color-secondary);
  --btn-secondary--colorH:  var(--color-secondary);
  --btn-secondary--bg:      'transparent';
  --btn-secondary--bgH:     'transparent';
  --btn-secondary--border:  var(--color-accent);
  --btn-secondary--borderH: var(--color-secondary);

  /* 2.2. END --------------------------------------------------------------- */

  /* 2. END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

  /* 3. PENDO GUIDES ++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

  --pendo-bg-color: var(--color-darker);
  --pendo-heading-color: var(--color-white);
  --pendo-heading-font: var(--family-secondary);
  --pendo-heading-size: var(--size-large);
  --pendo-heading-lineheight: var(--lineheight-normal);
  --pendo-heading-weight: var(--weight-bold);
  --pendo-text-font: var(--family-primary);
  --pendo-text-color: var(--color-light);
  --pendo-text-size: var(--size-small);
  --pendo-text-lineheight: var(--lineheight-small);
  --pendo-text-weight: var(--weight-light);
  --pendo-gutter-small: var(--gutter-small);
  --pendo-gutter: var(--gutter);
  --pendo-border-radius: var(--radius-small);
  --pendo-box-shadow: var(--shadow);
  --pendo-tooltip-caret: var(--color-darker);

  --pendo-btn-primary-bg-color: var(--color-wm-blueElectric);
  --pendo-btn-primary-bg-colorH: var(--color-white);
  --pendo-btn-primary-text-color: var(--color-darker);
  --pendo-btn-primary-text-colorH: var(--color-wm-blueElectric);
  --pendo-btn-border-color: var(--color-wm-blueElectric);
  --pendo-btn-border-colorH: var(--color-wm-blueElectric);

  --pendo-btn-secondary-bg-color: 'transparent';
  --pendo-btn-secondary-bg-colorH: 'transparent';
  --pendo-btn-secondary-text-color: var(--color-wm-blueElectric);
  --pendo-btn-secondary-text-colorH: var(--color-white);
  --pendo-btn-secondary-border-color: var(--color-wm-blueElectric);
  --pendo-btn-secondary-border-colorH: var(--color-white);

  --pendo-close-guide: var(--color-wm-blueElectric);
  --pendo-close-guideH: var(--color-white);

  /* 3. END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

    /* FORMS +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++* */

    /* FIELD */

    --field-label-color:              var(--color-darker);
    --field-help-color:               var(--color-dark);
    --field-error-color:              var(--color-red);

    /* END */

    /* INPUT */

    --input-color:                    var(--color-darker);
    --input-disabled-color:           var(--color-dark);

    --input-placeholder-color:        var(--color-dark);
    --input-placeholder-disabled-color: var(--color-light);

    --input-prefix-color:             var(--color-dark);
    --input-postfix-color:            var(--color-dark);

    --input-border-color:             var(--color);
    --input-border-disabled-color:    var(--color-light);
    --input-border-hover-color:       var(--color-dark);
    --input-border-focus-color:       var(--color-darker);

    /* END */


    /* END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  */

}

/* END OF FILE ############################################################## */
