/* CSS [ FONTS ] ############################################################ */

@font-face {
  font-family:Roboto;src:url(../fonts/Roboto-Light-webfont.woff) format("woff");font-weight:300;font-style:normal;
}

@font-face {
  font-family:Roboto;src:url(../fonts/Roboto-Medium-webfont.woff) format("woff");font-weight:500;font-style:normal;
}

@font-face {
  font-family:Gilroy;src:url(../fonts/gilroy-extrabold-webfont.woff) format("woff");font-weight:700;font-style:normal;
}

/* END OF FILE ############################################################## */
