/* LAYOUT [ ANONYMOUS > STYLESHEET ] ######################################## */

/* 1. ROOT VARIABLES ++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

:root {
  --anonymous-bg: var(--color);
  --anonymous-logo-bg: var(--color-wm-blueDark);
  --anonymous-copy-bg: var(--color-wm-blueDark);
  --anonymous-bg: var(--color);
  --anonymous-bg: url('https://wm-public-assets.s3.amazonaws.com/investor-platform/themes/default/background.jpg');
  --anonymous-bg-size: cover;
  --anonymous-heading-color: var(--color-accent);
  --anonymous-subheading-color: var(--color-white);
  --anonymous-text-color: var(--color-light);
  --anonymous-logo-height: 50px;
}

/* 1. END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

/* 1. CONTAINER +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

.l-anonymous {
  position: relative;
  background: var(--color-secondary) var(--anonymous-bg);
  background-repeat: no-repeat!important;
  background-size: var(--anonymous-bg-size)!important;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* 1. END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

/* 2. HEADER ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

.l-anonymous__header {
  flex: none;
}

.l-anonymous__header figure {
  
  width: fit-content;
  margin: 0;
  z-index: 10000;
  padding: var(--gutter-small);
  background-color: var(--anonymous-logo-bg);
}
.l-anonymous__header figure img {
  height: var(--anonymous-logo-height)!important;
  width: auto!important;
  max-width: none;
}

.l-anonymous__header aside {
  --arrow-color: var(--anonymous-copy-bg);
  background-color: var(--anonymous-copy-bg);
  padding: var(--gutter-medium);
  width: 40%;
  margin-top: var(--gutter-medium);
  margin-left: var(--gutter);
  box-shadow: var(--shadow);
}

.l-anonymous__header h1 {
  margin: 0 0 var(--gutter-small) 0;
  color: var(--anonymous-heading-color);
}
.l-anonymous__header h4 {
  margin: 0 0 var(--gutter-small) 0;
  color: var(--anonymous-subheading-color);
}
.l-anonymous__header p {
  color: var(--anonymous-text-color);
}

@media screen and (max-width: 480px) {

  .l-anonymous__header aside {
    padding: var(--gutter);
    max-width: 100%;
    width: auto;
    margin-top: var(--gutter);
    margin-left: var(--gutter-small);
    margin-right: var(--gutter-small);
    margin-bottom: var(--gutter);
  }

}

@media screen and (min-width: 481px) and (max-width: 1023px) {

  .l-anonymous__header aside {
    padding: var(--gutter);
    max-width: 100%;
    margin-top: var(--gutter);
    margin-left: var(--gutter);
    margin-right: var(--gutter-small);
    margin-bottom: var(--gutter);
  }

}

/* 2. END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

/* 2. CONTENT +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

.l-anonymous__content {
  
  position: fixed;
  top:0;
  right:0;
  bottom: 0;
  width: 45%;

  background-color: var(--color-white);

  overflow-y: auto;

}

@media screen and (max-width: 480px) {
  .l-anonymous__content {
    position: relative;
    margin: 0 var(--gutter-small);
    margin-bottom: var(--gutter);
    width: auto;
  }
}

@media screen and (min-width: 481px) and (max-width: 1023px) {

  .l-anonymous__content {
    width: 55%;
  }

}

/* 2. END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

/* 3. LOGO ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

.l-anonymous__footer {
  width: 55%;
}

@media screen and (max-width: 480px) {
  .l-anonymous__footer {
    width: 100%;
  }
}

@media screen and (min-width: 481px) and (max-width: 1023px) {

  .l-anonymous__footer {
    width: 45%;
  }

}


/* 3. END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

/* 4. COPY ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */



/* 4. END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

/* END OF FILE ############################################################## */
