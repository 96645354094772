/* CSS [ FEATURES ] ######################################################### */

/* 0. GLOBAL ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

:root {

  --step-color: var(--color-dark);
  --step-button-bg-color: transparent;
  --step-button-border-color: var(--color-light);

  --step-button-figure-bg-color: var(--color-dark);
  --step-button-figure-color: var(--color-white);

}

/* 0. END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

/* 1. STEPS +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

/* 1.1. GENERAL ------------------------------------------------------------- */

.f-steps {}
.f-steps > ul {
  justify-content: space-between;
}
.f-steps > ul > li {
  flex: 1;
  width: 100%;
}

/* Mobile screens */
@media screen and (max-width: 1023px) {
  .f-steps > ul {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }
  .f-steps > ul > li {
    flex: none;
    width: 45%;
    
  }
  .f-steps > ul > li:not(:last-child) {
    margin-bottom: var(--gutter-small);
  }
}

/* 1.1. END ----------------------------------------------------------------- */

/* 1.2. STEP LINK ----------------------------------------------------------- */

.f-steps__link {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  border-radius: var(--radius-large);

  padding: 0;

  width: 100%;

  background: var(--step-button-bg-color);
  border: solid 1px var(--step-button-border-color);
  font-size: var(--size-small);
  line-height: var(--lineheight-small);
  color: var(--step-color);
  transition: var(--transition-fast);
}

.f-steps__link figure {

  display: flex;
  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;
  border-radius: 100%;

  padding: 5px;
  margin-right: 5px;

  color: var(--step-button-figure-color);

  background: var(--step-button-figure-bg-color);
}

.f-steps__link figure svg {
  fill: var(--color-white)!important;
}
.f-steps__link figure i {
  font-style: normal;
  font-weight: var(--weight-bold);
  font-family: var(--family-secondary);
  color: var(--step-button-figure-color);
}
.f-steps__link > span {
  font-style: normal;
  font-weight: var(--weight-bold);
  font-family: var(--family-secondary);
  color: var(--step-color);
  text-transform: capitalize;
}

.f-steps__link--completed {
  --step-color: rgb(var(--color-green-dark));
  --step-button-bg-color: rgb(var(--color-green-light));
  --step-button-border-color: rgb(var(--color-green-light));
  --step-button-figure-bg-color: rgb(var(--color-green-dark));
  --step-button-figure-color: rgb(var(--color-green-light));
}
.f-steps__link--incomplete {
  --step-color: rgb(var(--color-orange-dark));
  --step-button-bg-color: rgb(var(--color-orange-light));
  --step-button-border-color: rgb(var(--color-orange-light));
  --step-button-figure-bg-color: rgb(var(--color-orange-dark));
  --step-button-figure-color: rgb(var(--color-orange-light));
}

.f-steps__link--active,
.f-steps__link.active {
  --step-color: rgb(var(--color-blue-dark));
  --step-button-bg-color: rgb(var(--color-blue-light));
  --step-button-border-color: rgb(var(--color-blue-light));
  --step-button-figure-bg-color: rgb(var(--color-blue-dark));
  --step-button-figure-color: rgb(var(--color-blue-light));
}

/* Mobile screens */
@media screen and (max-width: 1023px) {}

/* 1.2. END ----------------------------------------------------------------- */



/* 1. END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */


/* 2. SWITCHBOARD +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

/* 2.1. CONTAINER ----------------------------------------------------------- */

.f-switchboard > small {
  display: block;
  margin-bottom: var(--gutter-small);
}

.f-switchboard--with-arrows .p-button--active {
  position: relative;
}

.f-switchboard--with-arrows .p-button--active::after {
  content: "";

  width: 20px;
  height: 20px;
  
  position: absolute;
  
  background-color: var(--color-dark);

  clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
}

/* Mobile screens */
@media screen and (max-width: 480px) {

  .f-switchboard > ul {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .f-switchboard > ul > li {
    width: 100%;
    margin: 0 0 10px 0!important;
  }

  .f-switchboard > ul > li > a {
    width: 100%;
  }

  .f-switchboard > ul > li:not(:first-child) {
    flex: none;
    width: 48%;
    margin: 0 0 5px 0!important;
  }

}

/* 2.1. END ----------------------------------------------------------------- */

/* 2. END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

/* 3. LINK BOX ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

/* 3.1. CONTAINER ----------------------------------------------------------- */

.f-linkbox {
  background: var(--color-white);
  padding: var(--gutter-small);
}

.f-linkbox .o-field {
  margin: 0;
}

.f-linkbox figure {
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 2px var(--color-light);
  border-radius: 100%;
  padding: 2px;
}

.f-linkbox .p-button--complete > figure {
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 2px var(--color-green);
  border-radius: 100%;
  padding: 2px;
}

/* 3.1. END ----------------------------------------------------------------- */

/* 3. END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

/* 4. LINK BOX ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

/* 4.1. CONTAINER ----------------------------------------------------------- */

.f-actionbar {
  background: var(--color-white);
  padding: var(--gutter-small);
  border-radius: var(--radius-small);
}

.f-actionbar > ul {
  justify-content: flex-end;
}

/* 4.1. END ----------------------------------------------------------------- */

/* 4. END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

/* END OF FILE ############################################################## */
